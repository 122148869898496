@keyframes slideInFromLeft {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes slideInFromRight {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0);
    }
}
@keyframes slideInFromLeftSub {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes slideInFromRightSub {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0);
    }
}

.homeSubTitlesLeft {
    /* This section calls the slideInFromLeft animation we defined above */
    animation: 1s ease-out 0s 1 slideInFromLeftSub;
}

.homeSubTitlesRight {
    /* This section calls the slideInFromLeft animation we defined above */
    animation: 1s ease-out 0s 1 slideInFromRightSub;
}
.homeTitlesLeft {
    /* This section calls the slideInFromLeft animation we defined above */
    animation: 1s ease-out 0s 1 slideInFromLeft;
}

.homeTitlesRight {
    /* This section calls the slideInFromLeft animation we defined above */
    animation: 1s ease-out 0s 1 slideInFromRight;
}
