@keyframes slideInFromAbove {
    0% {
      transform: translateY(-100%);
      opacity: 0;
    }

    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  header {  
    animation: 1s ease-out 0s 1 slideInFromAbove;
  }